<template>
  <layout>
    <v-container fluid>
      <ais-instant-search :search-client="searchClient" index-name="movies">
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="2">
            <ais-hits-per-page
              :items="pages"
            >
              <template v-slot="{ items, refine }">
                <v-select
                  item-text="label"
                  :items="items"
                  :value="items.filter(x => x.isRefined === true)[0]"
                  @input="refine"
                ></v-select>
              </template>
            </ais-hits-per-page>

            <h2>Filters</h2>
            <ais-clear-refinements>
              <template v-slot="{ canRefine, refine }">
                <v-btn width="100%" color="primary" class="mb-2"
                  @click="refine"
                  :disabled="!canRefine">
                  Clear filters
                </v-btn>
              </template>
            </ais-clear-refinements>
            <ais-refinement-list
              attribute="genres.name"
              :limit="5"
              :show-more-limit="50"
              searchable
              show-more
            >
              <template v-slot="{ items, isShowingMore, isFromSearch, canToggleShowMore, refine, toggleShowMore, searchForItems }">
                <div v-if="isFromSearch && !items.length">No results.</div>
                <v-list dense flat subheader>
                  <v-subheader>Genres</v-subheader>
                  <v-subheader>
                    <v-text-field v-model="genreSearch" label="Search genres" @input="searchForItems"></v-text-field>
                  </v-subheader>
                  <v-list-item-group multiple v-model="genres">
                    <v-list-item v-if="isFromSearch && !items.length">
                      <v-list-item-content>
                        <v-list-item-title>No results.</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="item in items" :key="item.value" @click="refine(item.value)">
                      <template v-slot:default="{ toggle }">
                        <v-list-item-action>
                          <v-checkbox v-model="item.isRefined" color="primary" @click="toggle"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.label }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.count.toLocaleString() }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-btn color="primary" @click="toggleShowMore" :disabled="!canToggleShowMore" width="100%">
                  {{ !isShowingMore ? 'Show more' : 'Show less'}}
                </v-btn>
              </template>
            </ais-refinement-list>
            <ais-range-input attribute="vote_average">
              <template v-slot="{ currentRefinement, range, refine }">
                <h3 style="color: white">Notation</h3>
                <v-range-slider
                  class="mt-3"
                  :value="toValue(currentRefinement, range)"
                  :min="range.min"
                  :max="range.max"
                  @change="refine({ min: $event[0], max: $event[1] })"
                  :thumb-label="true"
                  :prepend-icon="'mdi-numeric-'+toValue(currentRefinement, range)[0]+'-box'"
                  :append-icon="'mdi-numeric-'+toValue(currentRefinement, range)[1]+'-box'"
                  step="1"
                ></v-range-slider>
              </template>
            </ais-range-input>
            <ais-configure :hitsPerPage="10"/>
          </v-col>
          <v-col cols="12" sm="8" md="8" lg="10">
            <ais-search-box>
              <template v-slot="{ isSearchStalled, refine }">
                <v-text-field
                  type="search"
                  label="Search" @input="refine"
                ></v-text-field>
                <span :hidden="!isSearchStalled">Loading...</span>
              </template>
            </ais-search-box>

            <ais-hits>
              <template v-slot="{ items }">
                <v-item-group>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex v-for="item in items" :key="item.objectID" xs4 md4 lg2>
                        <v-item v-slot:default="{ active, toggle }">
                          <Movie :active="active" :action="toggle" :movie="item"></Movie>
                        </v-item>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-item-group>
              </template>
            </ais-hits>

            <ais-pagination>
              <template v-slot="{ currentRefinement, nbPages, refine }">
                <v-pagination :value="currentRefinement + 1" :length="nbPages" @input="refine($event - 1)"
                ></v-pagination>
              </template>
            </ais-pagination>
            <ais-powered-by theme="dark" />
          </v-col>
        </v-row>
      </ais-instant-search>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import Movie from '../components/Movie';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';

export default {
  metaInfo: {
    title: 'Movies'
  },
  components: {
    Layout,
    Movie
  },
  data () {
    return {
      page: 1,
      searchClient: algoliasearch(
        '99XRH5RMXW',
        '7f933f63c0f9fcbbab2718ceb8addd60'
      ),
      genreSearch: null,
      genres: [],
      pages: [
        { label: '10 movies per page', value: 10 },
        { label: '20 movies per page', value: 20, default: true },
        { label: '50 movies per page', value: 50 },
        { label: '100 movies per page', value: 100 }
      ]
    };
  },
  methods: {
    imgObj (path) {
      return {
        src: 'https://assets.le-navet.com/static/800' + path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + path
      };
    },
    toValue (value, range) {
      return [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max
      ];
    }
  }
};
</script>
