var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-container',{attrs:{"fluid":""}},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"movies"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"2"}},[_c('ais-hits-per-page',{attrs:{"items":_vm.pages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
return [_c('v-select',{attrs:{"item-text":"label","items":items,"value":items.filter(function (x) { return x.isRefined === true; })[0]},on:{"input":refine}})]}}])}),_c('h2',[_vm._v("Filters")]),_c('ais-clear-refinements',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var canRefine = ref.canRefine;
var refine = ref.refine;
return [_c('v-btn',{staticClass:"mb-2",attrs:{"width":"100%","color":"primary","disabled":!canRefine},on:{"click":refine}},[_vm._v(" Clear filters ")])]}}])}),_c('ais-refinement-list',{attrs:{"attribute":"genres.name","limit":5,"show-more-limit":50,"searchable":"","show-more":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var isShowingMore = ref.isShowingMore;
var isFromSearch = ref.isFromSearch;
var canToggleShowMore = ref.canToggleShowMore;
var refine = ref.refine;
var toggleShowMore = ref.toggleShowMore;
var searchForItems = ref.searchForItems;
return [(isFromSearch && !items.length)?_c('div',[_vm._v("No results.")]):_vm._e(),_c('v-list',{attrs:{"dense":"","flat":"","subheader":""}},[_c('v-subheader',[_vm._v("Genres")]),_c('v-subheader',[_c('v-text-field',{attrs:{"label":"Search genres"},on:{"input":searchForItems},model:{value:(_vm.genreSearch),callback:function ($$v) {_vm.genreSearch=$$v},expression:"genreSearch"}})],1),_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.genres),callback:function ($$v) {_vm.genres=$$v},expression:"genres"}},[(isFromSearch && !items.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("No results.")])],1)],1):_vm._e(),_vm._l((items),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){return refine(item.value)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary"},on:{"click":toggle},model:{value:(item.isRefined),callback:function ($$v) {_vm.$set(item, "isRefined", $$v)},expression:"item.isRefined"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.count.toLocaleString()))])],1)]}}],null,true)})})],2)],1),_c('v-btn',{attrs:{"color":"primary","disabled":!canToggleShowMore,"width":"100%"},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? 'Show more' : 'Show less')+" ")])]}}])}),_c('ais-range-input',{attrs:{"attribute":"vote_average"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var range = ref.range;
var refine = ref.refine;
return [_c('h3',{staticStyle:{"color":"white"}},[_vm._v("Notation")]),_c('v-range-slider',{staticClass:"mt-3",attrs:{"value":_vm.toValue(currentRefinement, range),"min":range.min,"max":range.max,"thumb-label":true,"prepend-icon":'mdi-numeric-'+_vm.toValue(currentRefinement, range)[0]+'-box',"append-icon":'mdi-numeric-'+_vm.toValue(currentRefinement, range)[1]+'-box',"step":"1"},on:{"change":function($event){return refine({ min: $event[0], max: $event[1] })}}})]}}])}),_c('ais-configure',{attrs:{"hitsPerPage":10}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8","lg":"10"}},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSearchStalled = ref.isSearchStalled;
var refine = ref.refine;
return [_c('v-text-field',{attrs:{"type":"search","label":"Search"},on:{"input":refine}}),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])]}}])}),_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-item-group',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((items),function(item){return _c('v-flex',{key:item.objectID,attrs:{"xs4":"","md4":"","lg2":""}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('Movie',{attrs:{"active":active,"action":toggle,"movie":item}})]}}],null,true)})],1)}),1)],1)],1)]}}])}),_c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var nbPages = ref.nbPages;
var refine = ref.refine;
return [_c('v-pagination',{attrs:{"value":currentRefinement + 1,"length":nbPages},on:{"input":function($event){return refine($event - 1)}}})]}}])}),_c('ais-powered-by',{attrs:{"theme":"dark"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }